import { Navbar, Nav, Row, Col } from "reactstrap";
import PlaceholderLogo from "../assets/img/icons/Placeholder.svg?react";
import MonochromeCdataLogo from "../assets/img/icons/CDataLogoMonochrome.svg?react";
import MonochromeConnectLogo from "../assets/img/icons/ConnectLogoMonochrome.svg?react";
import { useAppSelector } from "../redux/hooks";
import "./OEMEditConnectionHeader.scss";

const OEMEditConnectionHeader = () => {
  const user = useAppSelector((s) => s.user);

  return (
    <Navbar
      color="white"
      className="components-oem-edit-connection-header"
      light
      expand
    >
      <Row className="logos">
        <Col className="section-oem-logo">
          {user.logoLink ? (
            <img src={user.logoLink} className="oem-custom-logo" alt="Logo" />
          ) : (
            <PlaceholderLogo
              className="oem-custom-logo"
              aria-description="Logo"
            />
          )}
        </Col>
        <Col className="section-powered-by">
          Powered by:
          <MonochromeCdataLogo
            className="cdata-logo-oem-monochrome"
            aria-description="CData logo"
          />
          <MonochromeConnectLogo
            className="connect-logo-oem-monochrome"
            aria-description="Connect logo"
          />
        </Col>
      </Row>
      <Nav className="ms-auto" navbar />
    </Navbar>
  );
};

export default OEMEditConnectionHeader;
